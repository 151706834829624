import React from "react";
import '../styles/about.scss'

// markup
const About = () => {

  return (
    <main className="about" >
      <div>
        <div>
          <p>As time progresses, a growing passion for graphical interfaces has manifested itself deep inside, much like a parasitic worm. </p>
          <p>With projects, I usually find myself exploring the potential of a good user experience. There are numerous products out there in the wild that accomplish the same task, and the only thing differentiating them is the look and feeling of the product. </p>
          <p>STOP, don't get the wrong picture. I still like to delve into other computer science fields, like exploring the most effective algorithms for the upcoming projects, adapt the project to use the correct framework and programming language and so on.</p>
          <p>I even find myself curious about development processes,  with the sweet spot being the Agile development cycle. The benefits of short iterations and continuous integrations, with short feedback loops to involve stakeholder, is extremely valuable</p>
        </div>
        <ul>
          <li>Java</li>
          <li>C#</li>
          <li>C++</li>
          <li>JavaScript</li>
          <li>React</li>
          <li>Haskell</li>
          <li>Agile development</li>
        </ul>
      </div>
    </main>
  )
}

export default About